<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import dataVariableGroup from "./variableGroup/index"
import dataVariable from "./variable/index"
// import Swal from "sweetalert2";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Data Variable",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, dataVariable, dataVariableGroup },
  data() {
    return {
    //   tableData: tableData,
      title: "Data Variable",
      items: [
        {
          text: "Master",
          href: "/"
        },
        {
          text: "Variable",
          active: true
        }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
   
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="card-body">
                <b-tabs class="mb-lg">
                    <b-tab title="Variable Group" active>
                        <dataVariableGroup/>
                    </b-tab>
                    <b-tab title="Variable" >
                        <dataVariable/>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>